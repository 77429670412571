import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { Switch } from "react-router";
import { ErrorBoundary } from "components/ErrorHandling/ErrorBoundary";
import { Activities } from "features/Activities/Activities";
import "./App.css";
import * as ROUTES from "./constants/routes";
import { LoginPage } from "features/Login/Login";
import LoginResult from "./features/Login/LoginResult";
import {
  getSelectedLocation,
  getServiceplanToken,
  removeSelectedLocation,
} from "api/serviceplanLocalStrorage";
import { TopBar } from "features/TopBar";
import { LocationSelector } from "features/LocationSelector/LocationSelector";
import { ServiceLocation } from "api/locations";
import { jwtDecode } from "jwt-decode";
export enum ScreenSize {
  NARROW = 1200,
  MEDIUM = 1590,
  LARGE = 1850,
  XLARGE = 100000,
}

export const getScreenSize = (width: number): ScreenSize => {
  if (width <= ScreenSize.NARROW) {
    return ScreenSize.NARROW;
  } else if (width <= ScreenSize.MEDIUM) {
    return ScreenSize.MEDIUM;
  } else if (width <= ScreenSize.LARGE) {
    return ScreenSize.LARGE;
  } else {
    return ScreenSize.XLARGE;
  }
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions(): { width: number; height: number } {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const initialLocationFromLS = getSelectedLocation();

const AuthenticatedApp: FunctionComponent = () => {
  let initialLocation: ServiceLocation | null;

  const [showPreviousDay, setShowPreviousDay] = useState<boolean>(false);

  try {
    initialLocation =
      initialLocationFromLS && JSON.parse(initialLocationFromLS);
  } catch {
    initialLocation = null;
    removeSelectedLocation();
  }

  const [selectedLocation, setSelectedLocation] =
    useState<ServiceLocation | null>(initialLocation);

  const onSetNewLocation = (newLocation: ServiceLocation | null) => {
    setSelectedLocation(newLocation);
    setShowPreviousDay(false);
  };

  useEffect(() => {
    if (selectedLocation) {
      localStorage.setItem(
        "SELECTED_LOCATION",
        JSON.stringify(selectedLocation)
      );
    }
  }, [selectedLocation]);

  return (
    <div className="App">
      <ErrorBoundary>
        <TopBar
          selectedLocation={selectedLocation}
          onSetNewLocation={onSetNewLocation}
          showPreviousDay={showPreviousDay}
          setShowPreviousDay={setShowPreviousDay}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        {selectedLocation ? (
          <Activities
            selectedLocation={selectedLocation}
            showPreviousDay={showPreviousDay}
          />
        ) : (
          <LocationSelector onSetNewLocation={onSetNewLocation} />
        )}
      </ErrorBoundary>
    </div>
  );
};

const UnAuthenticatedApp = () => (
  <Router>
    <Switch>
      <Route exact path={ROUTES.LOGIN_REDIRECT} component={LoginResult} />
      <Route exact path="/" component={LoginPage} />
      <Redirect to="/" />
    </Switch>
  </Router>
);

const accessToken = getServiceplanToken();
const currentTimeStamp = Date.now();
const ProvidedApp = () => {
  if (
    accessToken !== null &&
    currentTimeStamp < jwtDecode(accessToken).exp! * 1000
  ) {
    return <AuthenticatedApp />;
  }
  return <UnAuthenticatedApp />;
};

const App = (): ReactElement => <ProvidedApp />;

export default App;
