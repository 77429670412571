import styled, { css } from "styled-components";
import "../../index.css";

interface ITextProps {
  isLarge?: boolean;
  isLight?: boolean;
}

export const VyText = css`
  font-size: ${(props: ITextProps) => (props.isLarge ? "20px" : "16px")};
  font-family: ${(props: ITextProps) =>
    props.isLight ? "VySans-Light" : "VySans"};
  word-break: break-word;
  white-space: pre-line;
  color: black;
  margin: 0;
  text-align: center;
`;

export const Text = styled.p`
  ${VyText};
`;

export const Title = styled.h1`
  ${VyText};
  font-weight: bold;
`;

export const Link = styled.a`
  ${VyText};
  margin: 0;
`;
