/////////////////////////
// Bus activity:       //
/////////////////////////

export interface BusActivity {
  activityId: string;
  activityType: ActivityType;
  vehicleId: string;
  arrivalTime: string;
  startTime: string;
  endTime: string;
  lastDriverName: string;
  addedManually: boolean;
  rundvask: boolean;
  comment: string;
  tasks: Task[];
}

export interface BusActivityDto {
  activityId: string;
  activityType: string;
  vehicleId: string;
  arrivalTime: string;
  startTime: string;
  endTime: string;
  lastDriverName: string;
  addedManually: boolean;
  rundvask: boolean;
  comment: string;
  tasks: TaskDto[];
}

export interface BusActivityCollection {
  activities: BusActivityDto[];
}

export interface AddNewActivityRequest {
  activityType: string;
  date: string;
  location: string;
  startTime: string;
  endTime: string;
  vehicleId: string;
  arrivalTime: string;
  lastDriverName: string;
  rundvask: boolean;
}

export interface ActivityType {
  id: string;
  displayName_NO: string;
  displayName_SE: string;
  taskTypes: TaskType[];
}

export const ActivityTypes: { [key: string]: ActivityType } = {
  VASK_TANK: {
    id: "VASK_TANK",
    displayName_NO: "Vask og tank",
    displayName_SE: "Tvätta och tanka",
    taskTypes: [
      "EXTERNAL_WASH",
      "INTERNAL_WASH",
      "TANK",
      "COOLANT",
      "WASHER_FLUID",
      "OIL",
      "ADBLUE",
      "SAFETY_CHECK",
    ],
  },
  SIKKERHETSKONTROLL: {
    id: "SIKKERHETSKONTROLL",
    displayName_NO: "Sikkerhetskontroll",
    displayName_SE: "Säkerhetskontroll",
    taskTypes: ["SAFETY_CHECK"],
  },
  VASK_INNE: {
    id: "VASK_INNE",
    displayName_NO: "Vask inne",
    displayName_SE: "Städ av buss",
    taskTypes: ["INTERNAL_WASH"],
  },
  VASK_UTE: {
    id: "VASK_UTE",
    displayName_NO: "Vask ute",
    displayName_SE: "Tvätt av buss",
    taskTypes: ["EXTERNAL_WASH"],
  },
  TANKING: {
    id: "TANKING",
    displayName_NO: "Tanking av buss",
    displayName_SE: "Tankning av buss",
    taskTypes: ["TANK", "COOLANT", "WASHER_FLUID", "OIL", "ADBLUE"],
  },
};

export function getActivityTypeById(id: string): ActivityType {
  for (const key in ActivityTypes) {
    if (ActivityTypes.hasOwnProperty(key) && ActivityTypes[key].id === id) {
      return ActivityTypes[key];
    }
  }
  return ActivityTypes.VASK_TANK;
}

/////////////////////////
// Tasks:              //
/////////////////////////
export type TaskType =
  | "EXTERNAL_WASH"
  | "INTERNAL_WASH"
  | "TANK"
  | "SAFETY_CHECK"
  | "COOLANT"
  | "WASHER_FLUID"
  | "OIL"
  | "ADBLUE"
  | "RUNDVASK";

export const TaskNames: {
  [key: string]: { name_no: string; name_se: string };
} = {
  EXTERNAL_WASH: { name_no: "Utvendig vask", name_se: "Tvätt av buss" },
  INTERNAL_WASH: { name_no: "Innvendig vask", name_se: "Städ av buss" },
  TANK: { name_no: "Tanking", name_se: "Tankning" },
  SAFETY_CHECK: { name_no: "Sikkerhetskontroll", name_se: "Säkerhetskontroll" },
  COOLANT: { name_no: "Kjølevæske", name_se: "Kylvätska" },
  WASHER_FLUID: { name_no: "Spylervæske", name_se: "Spolarvätska" },
  OIL: { name_no: "Olje", name_se: "Olja" },
  ADBLUE: { name_no: "AdBlue", name_se: "AdBlue" },
  RUNDVASK: { name_no: "Rundvask", name_se: "Rundvask" },
};

export interface Task {
  taskType: TaskType;
  isCompleted: boolean;
  status: "Loading" | "Success" | "Default" | "Failure";
}

export interface TaskDto {
  taskType: TaskType;
  isCompleted: boolean;
}

/////////////////////////
// Other:              //
/////////////////////////

export interface AddBusFormData {
  busNr: string;
  activityType: string | null;
  startDate: string;
  startTime: string;
  endTime: string;
  rundvask: boolean;
}

export interface AddCommentDTO {
  comment: string;
}
