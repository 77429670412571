import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { ReactComponent as BusIconSVG } from "features/ActivityList/icons/BusIcon.svg";
import { ReactComponent as BusIconManualSVG } from "features/ActivityList/icons/BusIconManual.svg";
import { Stack, Text } from "@vygruppen/spor-react";
import { ServiceLocation } from "../../api/locations";

const BusIconContainer = styled.div`
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
  & > *:first-child {
    margin-right: 2px;
  }
`;

const BusIcon = styled(BusIconSVG)`
  height: 30px;
  width: 30px;
  padding-right: 5px;
`;

const BusIconManual = styled(BusIconManualSVG)`
  height: 30px;
  width: 30px;
  padding-right: 5px;
`;

interface BusIconDescriptionProps {
  selectedLocation: ServiceLocation;
}

export const BusIconDescription: FunctionComponent<BusIconDescriptionProps> = ({
  selectedLocation,
}) => {
  return (
    <Stack>
      <BusIconContainer>
        <BusIcon />
        <Text variant="sm">
          {selectedLocation.countryCode === "SE"
            ? "Läggs till automatiskt"
            : "Lagt inn automatisk"}
        </Text>
      </BusIconContainer>
      <BusIconContainer>
        <BusIconManual />
        <Text variant="sm">
          {selectedLocation.countryCode === "SE"
            ? "Läggs till manuelt"
            : "Lagt inn manuelt"}
        </Text>
      </BusIconContainer>
    </Stack>
  );
};
