import React, { FunctionComponent, useEffect, useState } from "react";
import { ActivityList } from "features/ActivityList/ActivityList";
import { useRemoteDataGet } from "api/remoteData";
import { ErrorMessage } from "components/ErrorHandling/ErrorMessage";
import { useActivityTasks } from "features/Activities/useActivityTasks";
import {
  BusActivity,
  BusActivityDto,
  getActivityTypeById,
} from "features/Activities/types";
import { getActivitiesForLocation } from "api/activities";
import { ServiceLocation } from "api/locations";
import { ActivityListToolbar } from "../ActivityListToolbar/ActivityListToolbar";
import { ListContainer } from "../ActivityList/style";
import { ErrorMessageContainer } from "../../components/ErrorHandling/ErrorMessageContainer";
import { ContentLoader, Text } from "@vygruppen/spor-react";

const EVERY_MINUTE = 60 * 1000;

export type BusActivitiesProps = {
  selectedLocation: ServiceLocation;
  showPreviousDay: boolean;
};

export const Activities: FunctionComponent<BusActivitiesProps> = ({
  selectedLocation,
  showPreviousDay,
}) => {
  const [showActivityList, setShowActivityList] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");

  const { remoteData: busActivities, refresh: refreshBusActivities } =
    useRemoteDataGet(
      getActivitiesForLocation(selectedLocation.id, showPreviousDay)
    );

  const { clearFinishedRequests, getLastStartedTaskRequest, markTaskAs } =
    useActivityTasks();

  const mapTaskRequestsToBusActivities = (
    activities: BusActivityDto[]
  ): BusActivity[] =>
    activities.map((a) => ({
      ...a,
      activityType: getActivityTypeById(a.activityType),
      tasks: a.tasks.map((t) => {
        const taskRequest = getLastStartedTaskRequest(a.activityId, t.taskType);

        if (taskRequest.request != null) {
          return {
            taskType: t.taskType,
            isCompleted: taskRequest.type === "Complete",
            status: taskRequest.request.status,
          };
        }

        return {
          taskType: t.taskType,
          isCompleted: t.isCompleted,
          status: "Default",
        };
      }),
    }));

  useEffect(() => {
    const intervalId = setInterval(() => {
      refreshBusActivities();
    }, EVERY_MINUTE);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (busActivities.status === "Success") {
      clearFinishedRequests();
      setShowActivityList(busActivities.data.activities.length > 0);
    }
  }, [busActivities.status]);

  useEffect(() => {
    refreshBusActivities();
  }, [showPreviousDay]);

  let activityList;
  switch (busActivities.status) {
    case "NotAsked":
    case "Loading":
      return (
        <ContentLoader>
          <Text>
            {selectedLocation.countryCode === "SE"
              ? "Vänligen vänta medan vi hittar aktiviteterna."
              : "Vennligst vent mens vi finner aktivitetene."}
          </Text>
        </ContentLoader>
      );
    case "Failure":
      return <ErrorMessage />;
    case "Success":
      activityList = busActivities.data.activities;
      break;
    case "ShouldRefresh":
    case "Refreshing":
      activityList = busActivities.prevData.activities;
      break;
  }
  return (
    <ListContainer style={{ marginTop: "20px" }}>
      <ActivityListToolbar
        selectedLocation={selectedLocation}
        searchText={searchText}
        setSearchText={setSearchText}
        showActivityList={showActivityList}
        refreshBusActivities={refreshBusActivities}
      />
      {showActivityList ? (
        <ActivityList
          activityList={mapTaskRequestsToBusActivities(activityList)}
          markTaskAs={markTaskAs}
          selectedLocation={selectedLocation}
          showPreviousDay={showPreviousDay}
          searchText={searchText}
          refreshBusActivities={refreshBusActivities}
        />
      ) : (
        <ErrorMessageContainer>
          <Text>
            {selectedLocation.countryCode === "SE"
              ? "Hittade inga aktiviteter för"
              : "Fant ingen aktiviteter for"}{" "}
            {selectedLocation.name}
            {"."}
          </Text>
        </ErrorMessageContainer>
      )}
    </ListContainer>
  );
};
