import React from "react";
import "./index.css";
import App from "./App";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "styled-components";
import { defaultTheme } from "style/themes/defaultTheme";
import { SporProvider } from "@vygruppen/spor-react";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <SporProvider>
      <ThemeProvider theme={defaultTheme}>
        <App />
      </ThemeProvider>
    </SporProvider>
  </React.StrictMode>
);
