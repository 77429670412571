import { environment, Environment } from "environment";
import pkceChallenge from "pkce-challenge";
import { LOGIN_REDIRECT } from "constants/routes";
import {
  createCognitoState,
  getCognitoState,
} from "api/serviceplanLocalStrorage";

export const getCognitoBaseUrl = (): string => {
  switch (environment()) {
    case Environment.Test:
      return "https://auth.test.cognito.vydev.io";
    case Environment.Development:
      return "https://auth.test.cognito.vydev.io";
    case Environment.Production:
      return "https://auth.cognito.vydev.io";
    case Environment.Stage:
      return "https://auth.stage.cognito.vydev.io";
  }
};

export const getCognitoClientId = (): string => {
  switch (environment()) {
    case Environment.Test:
      return "729im0oaf603brdb17unqpnu4b";
    case Environment.Development:
      return "729im0oaf603brdb17unqpnu4b";
    case Environment.Stage:
      return "7e1ekfpq88s6vmgn2446tphdjb";
    case Environment.Production:
      return "7ul65b95f5jngjd5dof9nfq4hd";
  }
};

export interface pkceObject {
  code_challenge: string;
  code_verifier: string;
}

const getPKCETokens = () => {
  const pkceChallengeObject: pkceObject = pkceChallenge();
  return pkceChallengeObject;
};

const setPKCETokenInLocalStorage = (pkceChallengeObject: pkceObject) => {
  localStorage.setItem("PKCE_CHALLENGE", pkceChallengeObject.code_challenge);
  localStorage.setItem("PKCE_CODE_VERIFIER", pkceChallengeObject.code_verifier);
};

export const LoginPage = (): null => {
  createCognitoState();
  const pkceChallengeObject = getPKCETokens();
  setPKCETokenInLocalStorage(pkceChallengeObject);
  window.location.href = `${getCognitoBaseUrl()}/oauth2/authorize?response_type=code&client_id=${getCognitoClientId()}&code_challenge=${
    pkceChallengeObject.code_challenge
  }&redirect_uri=${
    window.location.origin + LOGIN_REDIRECT
  }&code_challenge_method=S256&state=${getCognitoState()}`;
  return null;
};
