import React, { ReactElement, useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { getCognitoBaseUrl, getCognitoClientId } from "features/Login/Login";
import { LOGIN_REDIRECT } from "constants/routes";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "api/serviceplanLocalStrorage";
import {
  SubTitle,
  MainTitle,
  LoaderContainer,
} from "features/Login/LoginStyle";
import { AppContainer } from "style/CommonStyle";
import { Loading } from "components/Loading/Loading";
import { ContentLoader } from "@vygruppen/spor-react";

export interface CognitoResponse {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  token_type: string;
}

const LoginResult = (): ReactElement => {
  const authenticationCode = new URLSearchParams(window.location.search).get(
    "code"
  );
  const pkceCodeVerifier = localStorage.getItem("PKCE_CODE_VERIFIER");
  const [token, setToken] = useState<CognitoResponse>();

  if (!authenticationCode || !pkceCodeVerifier) {
    return (
      <AppContainer>
        <MainTitle style={{ color: "white" }}>
          Autentisering mislyktes
        </MainTitle>
        <SubTitle style={{ color: "white" }}>Venligst prøv igjen</SubTitle>
      </AppContainer>
    );
  }

  useEffect(() => {
    axios
      .request<string, AxiosResponse<CognitoResponse>>({
        method: "POST",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
        url: `${getCognitoBaseUrl()}/oauth2/token`,
        data: `code=${authenticationCode}&code_verifier=${pkceCodeVerifier}&redirect_uri=${
          window.location.origin + LOGIN_REDIRECT
        }&client_id=${getCognitoClientId()}&grant_type=authorization_code`,
      })
      .then((response) => {
        setToken(response.data);
      });
  }, []);

  useEffect(() => {
    if (token?.access_token) {
      localStorage.setItem(ACCESS_TOKEN, `${token.access_token}`);
      localStorage.setItem(REFRESH_TOKEN, token.refresh_token);
      window.location.href = "/";
    }
  }, [token]);

  if (token?.access_token) {
    return (
      <AppContainer>
        <LoaderContainer>
          <SubTitle style={{ color: "black" }}>Logger inn</SubTitle>
          <ContentLoader />
        </LoaderContainer>
      </AppContainer>
    );
  }

  return <div />;
};

export default LoginResult;
