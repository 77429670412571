enum Environment {
  Development = "DEV",
  Test = "TEST",
  Stage = "STAGE",
  Production = "PROD",
}

const environment = (): Environment => {
  if (
    process.env.NODE_ENV === "development" ||
    window.location.origin.includes("localhost") ||
    window.location.origin.includes("127.0.0.1")
  ) {
    return Environment.Development;
  }
  if (
    window.location.origin.endsWith("https://serviceplan.test.vybus.vydev.io")
  ) {
    return Environment.Test;
  }
  if (
    window.location.origin.endsWith("https://serviceplan.stage.vybus.vydev.io")
  ) {
    return Environment.Stage;
  }
  if (
    window.location.origin.endsWith("https://serviceplan.vybus.vydev.io") ||
    window.location.origin.endsWith("https://serviceplan.vy.no") ||
    window.location.origin.endsWith("https://serviceplan.buss.vy.no")
  ) {
    return Environment.Production;
  }
  throw new Error("Fant ikke riktig miljø");
};

export { Environment, environment };
