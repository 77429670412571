import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { RowElementContainer } from "features/ActivityList/style";
import { Tooltip, Text } from "@vygruppen/spor-react";
import { ReactComponent as BusWashSVG } from "features/ActivityList/icons/VaskBuss.svg";
import { ReactComponent as WashSVG } from "features/ActivityList/icons/Vask.svg";
import { ReactComponent as TankSVG } from "features/ActivityList/icons/Tank.svg";
import { ReactComponent as CoolantSVG } from "features/ActivityList/icons/Coolant.svg";
import { ReactComponent as WasherFluidSVG } from "features/ActivityList/icons/WasherFluid.svg";
import { ReactComponent as OilSVG } from "features/ActivityList/icons/Oil.svg";
import { ReactComponent as SafetyCheckSVG } from "features/ActivityList/icons/SafetyCheck.svg";
import { ReactComponent as AdBlueSVG } from "features/ActivityList/icons/AdBlue.svg";
import { ReactComponent as RundvaskSVG } from "features/ActivityList/icons/Rundvask.svg";
import { ServiceLocation } from "../../api/locations";
import { safetyCheckEnabledForLocation } from "./LocationConfiguration";
import { getScreenSize, ScreenSize, useWindowDimensions } from "App";
import { getColumnSize } from "./Size";

const BusWashIcon = styled(BusWashSVG)`
  width: 30px;
`;

const WashIcon = styled(WashSVG)`
  width: 30px;
`;

const TankIcon = styled(TankSVG)`
  width: 30px;
`;

const CoolantIcon = styled(CoolantSVG)`
  width: 30px;
`;

const WasherFluidIcon = styled(WasherFluidSVG)`
  width: 30px;
`;

const OilIcon = styled(OilSVG)`
  width: 30px;
`;

const SafetyCheckIcon = styled(SafetyCheckSVG)`
  width: 30px;
`;

const AdBlueIcon = styled(AdBlueSVG)`
  width: 30px;
`;

const RundVaskIcon = styled(RundvaskSVG)`
  width: 30px;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 95px;
  background-color: #b9c9de;
  padding-left: 24px;
  margin-top: 20px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  z-index: 1;
`;

interface IHeaderProps {
  selectedLocation: ServiceLocation;
  containsRundvask: boolean;
}

export const Header: FunctionComponent<IHeaderProps> = ({
  selectedLocation,
  containsRundvask,
}) => {
  const { width } = useWindowDimensions();
  const screenSize = getScreenSize(width);
  const columnSize = getColumnSize(screenSize);

  return (
    <HeaderContainer>
      <RowElementContainer
        minWidth={screenSize < ScreenSize.LARGE ? "70px" : "125px"}
        width={screenSize < ScreenSize.LARGE ? "70px" : "null"}
      >
        <Text>Tid</Text>
      </RowElementContainer>
      <RowElementContainer
        minWidth={screenSize <= ScreenSize.NARROW ? "90px" : "140px"}
        width={screenSize <= ScreenSize.NARROW ? "90px" : "140px"}
      >
        <Text>Buss</Text>
      </RowElementContainer>
      <RowElementContainer
        minWidth={columnSize.minWidth}
        width={columnSize.width}
      >
        {screenSize > ScreenSize.NARROW ? (
          <>
            <Text align={"center"}>
              {selectedLocation.countryCode === "SE" ? (
                <>
                  <br />
                  Tankning
                </>
              ) : (
                <>
                  <br />
                  Tanking
                </>
              )}
            </Text>
            <TankIcon />
          </>
        ) : (
          <Tooltip
            content={
              selectedLocation.countryCode == "SE" ? "Tankning" : "Tanking"
            }
          >
            <TankIcon />
          </Tooltip>
        )}
      </RowElementContainer>
      <RowElementContainer
        minWidth={columnSize.minWidth}
        width={columnSize.width}
      >
        {screenSize > ScreenSize.NARROW ? (
          <>
            <Text align={"center"}>
              {selectedLocation.countryCode === "SE" ? (
                <>
                  Städ
                  <br />
                  av buss
                </>
              ) : (
                <>
                  Vask
                  <br />
                  inne
                </>
              )}
            </Text>
            <WashIcon />
          </>
        ) : (
          <Tooltip
            content={
              selectedLocation.countryCode == "SE"
                ? "Städ av buss"
                : "Vask inne"
            }
          >
            <WashIcon />
          </Tooltip>
        )}
      </RowElementContainer>
      <RowElementContainer
        minWidth={columnSize.minWidth}
        width={columnSize.width}
      >
        {screenSize > ScreenSize.NARROW ? (
          <>
            <Text align={"center"}>
              {selectedLocation.countryCode === "SE" ? (
                <>
                  Tvätt
                  <br />
                  av buss
                </>
              ) : (
                <>
                  Vask
                  <br />
                  ute
                </>
              )}
            </Text>
            <BusWashIcon />
          </>
        ) : (
          <Tooltip
            content={
              selectedLocation.countryCode == "SE"
                ? "Tvätt av buss"
                : "Vask ute"
            }
          >
            <BusWashIcon />
          </Tooltip>
        )}
      </RowElementContainer>
      <RowElementContainer
        minWidth={columnSize.minWidth}
        width={columnSize.width}
      >
        {screenSize > ScreenSize.NARROW ? (
          <>
            <Text align={"center"}>
              {selectedLocation.countryCode === "SE" ? (
                <>
                  Kyl-
                  <br />
                  vätska
                </>
              ) : (
                <>
                  Kjøle-
                  <br />
                  væske
                </>
              )}
            </Text>
            <CoolantIcon />
          </>
        ) : (
          <Tooltip
            content={
              selectedLocation.countryCode == "SE" ? "Kylvätska" : "Kjølevæske"
            }
          >
            <CoolantIcon />
          </Tooltip>
        )}
      </RowElementContainer>
      <RowElementContainer
        minWidth={columnSize.minWidth}
        width={columnSize.width}
      >
        {screenSize > ScreenSize.NARROW ? (
          <>
            <Text align={"center"}>
              {selectedLocation.countryCode === "SE" ? (
                <>
                  Spolar-
                  <br />
                  vätska
                </>
              ) : (
                <>
                  Spyler-
                  <br />
                  væske
                </>
              )}
            </Text>
            <WasherFluidIcon />
          </>
        ) : (
          <Tooltip
            content={
              selectedLocation.countryCode == "SE"
                ? "Spolarvätska"
                : "Spylervæske"
            }
          >
            <WasherFluidIcon />
          </Tooltip>
        )}
      </RowElementContainer>
      <RowElementContainer
        minWidth={columnSize.minWidth}
        width={columnSize.width}
      >
        {screenSize > ScreenSize.NARROW ? (
          <>
            <Text align={"center"}>
              {selectedLocation.countryCode === "SE" ? (
                <>
                  <br />
                  Olja
                </>
              ) : (
                <>
                  <br />
                  Olje
                </>
              )}
            </Text>
            <OilIcon />
          </>
        ) : (
          <Tooltip
            content={selectedLocation.countryCode == "SE" ? "Olja" : "Olje"}
          >
            <OilIcon />
          </Tooltip>
        )}
      </RowElementContainer>
      <RowElementContainer
        minWidth={columnSize.minWidth}
        width={columnSize.width}
      >
        {screenSize > ScreenSize.NARROW ? (
          <>
            <Text align={"center"}>
              <>
                <br />
                AdBlue
              </>
            </Text>
            <AdBlueIcon />
          </>
        ) : (
          <Tooltip content={"AdBlue"}>
            <AdBlueIcon />
          </Tooltip>
        )}
      </RowElementContainer>
      {safetyCheckEnabledForLocation(selectedLocation) && (
        <RowElementContainer
          minWidth={columnSize.minWidth}
          width={columnSize.width}
        >
          {screenSize > ScreenSize.NARROW ? (
            <>
              <Text align={"center"}>
                {selectedLocation.countryCode === "SE" ? (
                  <>
                    Säkerhets-
                    <br />
                    kontroll
                  </>
                ) : (
                  <>
                    Sikkerhets-
                    <br />
                    kontroll
                  </>
                )}
              </Text>
              <SafetyCheckIcon />
            </>
          ) : (
            <Tooltip
              content={
                selectedLocation.countryCode === "SE"
                  ? "Säkerhetskontroll"
                  : "Sikkerhetskontroll"
              }
            >
              <SafetyCheckIcon />
            </Tooltip>
          )}
        </RowElementContainer>
      )}
      {containsRundvask && (
        <RowElementContainer
          minWidth={columnSize.minWidth}
          width={columnSize.width}
        >
          {screenSize > ScreenSize.NARROW ? (
            <>
              <Text align={"center"}>
                <>
                  <br />
                  Rundvask
                </>
              </Text>
              <RundVaskIcon />
            </>
          ) : (
            <Tooltip content={"Rundvask"}>
              <RundVaskIcon />
            </Tooltip>
          )}
        </RowElementContainer>
      )}
    </HeaderContainer>
  );
};
