import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { sendLogToCloudWatch } from "./index";
import { LogLevel, LogPayload } from "components/ErrorHandling/LogComponent";
import { config } from "./common";
import { Environment, environment } from "../environment";

interface NotAsked {
  status: "NotAsked";
}

interface ShouldRefresh<T> {
  status: "ShouldRefresh";
  prevData: T;
}

interface Loading {
  status: "Loading";
}

interface Refreshing<T> {
  status: "Refreshing";
  prevData: T;
}

interface Success<T> {
  status: "Success";
  data: T;
}

interface Failure<E> {
  status: "Failure";
  error: E;
}

// <T> Er responstypen til requesten, og vi bruker den i useRemoteData.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface RemoteDataGetRequest<T> {
  url: string;
}

export const NotAsked: NotAsked = { status: "NotAsked" };
export const Loading: Loading = { status: "Loading" };
export const Success = <T>(data: T): Success<T> => ({
  status: "Success",
  data,
});
export const Failure = <E>(error: E): Failure<E> => ({
  status: "Failure",
  error,
});
export const ShouldRefresh = <T>(prevData: T): ShouldRefresh<T> => ({
  status: "ShouldRefresh",
  prevData,
});
export const Refreshing = <T>(prevData: T): Refreshing<T> => ({
  status: "Refreshing",
  prevData,
});

type Error = AxiosError;

interface UseRemoteDataGetApi<T> {
  remoteData: RemoteData<T, Error>;
  refresh: () => void;
}

export type RemoteData<T, E> =
  | NotAsked
  | Loading
  | Success<T>
  | Failure<E>
  | ShouldRefresh<T>
  | Refreshing<T>;

export const useRemoteDataGet = <T>(
  request: RemoteDataGetRequest<T>
): UseRemoteDataGetApi<T> => {
  const [remoteData, setRemoteData] = useState<RemoteData<T, Error>>(NotAsked);

  const refresh = () => {
    setRemoteData((prevRemoteData) => {
      if (prevRemoteData.status === "Success") {
        return ShouldRefresh(prevRemoteData.data);
      } else if (prevRemoteData.status === "Failure") {
        return NotAsked;
      }
      return prevRemoteData;
    });
  };

  useEffect(() => {
    const getData = () =>
      axios
        .get(request.url, config)
        .then((response) => {
          setRemoteData(Success(response.data));
        })
        .catch((error) => {
          if (environment() !== Environment.Development) {
            sendLogToCloudWatch(
              LogPayload(LogLevel.warn, JSON.stringify(error))
            );
          }
          setRemoteData(Failure(error));
        });

    if (remoteData.status === "NotAsked") {
      setRemoteData(Loading);
      getData();
    } else if (remoteData.status === "ShouldRefresh") {
      setRemoteData(Refreshing(remoteData.prevData));
      getData();
    }
  }, [remoteData.status]);

  return {
    remoteData,
    refresh,
  };
};
