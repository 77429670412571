import { deleteActivity } from "api/activities";
import { useRemoteAction } from "../../api/remoteAction";
import { v4 as uuidv4 } from "uuid";

export const useDeleteActivity = () => {
  const {
    get: getDeleteActivityRequest,
    send: sendDeleteActivityRequest,
    clearFinished: clearFinishedAddActivityRequest,
  } = useRemoteAction(deleteActivity);

  const deleteActivityById = (activityId: string): string => {
    const requestUUID = uuidv4();
    sendDeleteActivityRequest(requestUUID, { activityId: activityId });
    return requestUUID;
  };

  return {
    deleteActivityById,
    getDeleteActivityRequest,
    clearFinishedAddActivityRequest,
  };
};
