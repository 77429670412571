import React, { Component, ErrorInfo, ReactNode } from "react";
import { LogLevel, LogPayload } from "./LogComponent";
import { sendLogToCloudWatch } from "api";
import { ErrorMessage } from "./ErrorMessage";

type ErrorState = {
  errorInfo: ErrorInfo | null;
};

export class ErrorBoundary extends Component<ReactNode, ErrorState> {
  constructor(props: ReactNode) {
    super(props);
    this.state = { errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ errorInfo });
    try {
      sendLogToCloudWatch(
        LogPayload(
          LogLevel.error,
          error.message,
          errorInfo.componentStack.toString()
        )
      );
    } catch (e) {
      console.log(e);
    }
  }

  render(): ReactNode {
    const { props, state } = this;
    if (state.errorInfo) {
      return <ErrorMessage />;
    }
    return props.children;
  }
}
