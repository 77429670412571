import React, { useEffect, useState } from "react";
import { AddBusFormData, AddNewActivityRequest } from "../Activities/types";
import { ServiceLocation } from "../../api/locations";
import { useAddActivity } from "../Activities/useAddActivity";
import { SearchInput, useToast } from "@vygruppen/spor-react";
import { AddBusManually } from "./AddBusManually";
import { ActivityListToolbarContainer } from "../ActivityList/style";

interface ActivitiesToolbarProps {
  selectedLocation: ServiceLocation;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  showActivityList: boolean;
  refreshBusActivities: () => void;
}
export const ActivityListToolbar: React.FC<ActivitiesToolbarProps> = ({
  selectedLocation,
  searchText,
  setSearchText,
  showActivityList,
  refreshBusActivities,
}) => {
  const {
    addActivity,
    getAddActivityRequest,
    clearFinishedAddActivityRequest,
  } = useAddActivity();

  const toast = useToast();

  const [requestId, setRequestId] = useState<string>("");
  const [responseCheck, setResponseCheck] = useState<number>(0);
  const [resetForm, setResetForm] = useState<boolean>(true);

  const checkResponse = (requestId: string) => {
    setResponseCheck(1);
    setRequestId(requestId);
  };

  useEffect(() => {
    if (responseCheck !== 0 && requestId !== "") {
      const res = getAddActivityRequest(requestId);
      if (res === undefined) {
        const toastText =
          selectedLocation.countryCode === "SE"
            ? "Något gick fel. Försök igen eller kontakta administratören."
            : "Det skjedde noe feil. Prøv igjen eller kontakt administrator.";

        toast({ variant: "error", text: toastText });
        return;
      }
      if (res.status === "Success") {
        const toastText =
          selectedLocation.countryCode === "SE"
            ? "Buss " + res.body.vehicleId + " har lagts till!"
            : "Buss " + res.body.vehicleId + " ble lagt til!";
        toast({ variant: "success", text: toastText });

        clearFinishedAddActivityRequest();
        setRequestId("");
        setResponseCheck(0);

        refreshBusActivities();
      } else if (res.status === "Failure") {
        const toastText =
          selectedLocation.countryCode === "SE"
            ? "Kunde inte lägga till" +
              res.body.vehicleId +
              ". Försök igen eller kontakta administratören."
            : "Kunne ikke legge til " +
              res.body.vehicleId +
              ". Prøv igjen eller kontakt administrator";

        toast({ variant: "error", text: toastText });

        setResetForm(false);
      } else {
        setTimeout(() => {
          setResponseCheck(responseCheck + 1);
        }, 100);
      }
    }
  }, [responseCheck]);

  const onAddBusManually = (busObj: AddBusFormData) => {
    if (busObj !== null && selectedLocation !== null) {
      const busActivity: AddNewActivityRequest = {
        date: busObj.startDate.slice(0, 10),
        activityType: busObj.activityType ? busObj.activityType : "VASK_TANK",
        location: selectedLocation.id,
        startTime: busObj.startTime,
        endTime: busObj.endTime,
        vehicleId: busObj.busNr,
        arrivalTime: busObj.startTime,
        rundvask: busObj.rundvask,
        lastDriverName: "",
      };
      try {
        const requestId = addActivity(busActivity);
        checkResponse(requestId);
      } catch (e) {
        console.log(e);
      }
    }
  };
  return (
    <ActivityListToolbarContainer>
      {showActivityList && (
        <SearchInput
          style={{ background: "white" }}
          label={
            selectedLocation.countryCode === "SE"
              ? "Sök på bussnummer"
              : "Søk etter bussnummer"
          }
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onReset={() => setSearchText("")}
        />
      )}

      <div>
        <AddBusManually
          onAddBusManually={onAddBusManually}
          resetForm={resetForm}
          setResetForm={setResetForm}
          selectedLocation={selectedLocation}
        />
      </div>
    </ActivityListToolbarContainer>
  );
};
