import React, { useEffect, useState, FunctionComponent } from "react";
import { format } from "date-fns";
import { nb } from "date-fns/locale";

const Clock: FunctionComponent = () => {
  const prettyTime = () =>
    format(new Date(), "HH:mm - EEEE do LLLL", { locale: nb }); // Feks "16:35 - fredag 16. april"

  const [time, setTime] = useState<string>(prettyTime());

  useEffect(() => {
    const timerID = setInterval(() => setTime(prettyTime), 1000);
    return function cleanup() {
      clearInterval(timerID);
    };
  }, []);

  return <p style={{ padding: 0, margin: 0 }}>{time}</p>;
};

export default Clock;
