import { postActivity } from "api/activities";
import { useRemoteAction } from "../../api/remoteAction";
import { AddNewActivityRequest } from "./types";
import { v4 as uuidv4 } from "uuid";

export const useAddActivity = () => {
  const {
    get: getAddActivityRequest,
    send: sendAddActivityRequest,
    clearFinished: clearFinishedAddActivityRequest,
  } = useRemoteAction(postActivity);

  const addActivity = (busActivityRequest: AddNewActivityRequest): string => {
    const requestUUID = uuidv4();
    sendAddActivityRequest(requestUUID, { param: null }, busActivityRequest);
    return requestUUID;
  };

  return {
    addActivity,
    getAddActivityRequest,
    clearFinishedAddActivityRequest,
  };
};
