import React, { ReactElement } from "react";
import { ErrorMessageContainer } from "./ErrorMessageContainer";
import { Link, Title } from "../Text/StyledTexts";
import { Text } from "@vygruppen/spor-react";

export const ErrorMessage = (): ReactElement => (
  <ErrorMessageContainer>
    <Text align={"center"} as={"h3"} variant={"md"}>
      Noe gikk galt
    </Text>
    <br />
    <Text align={"center"}>
      Vennligst prøv igjen.
      <br />
      Hvis problemet vedvarer, ta kontakt med administrator{" "}
      <Link href="mailto:raymond.frog@vy.no">her.</Link>
      <br />
    </Text>
  </ErrorMessageContainer>
);
