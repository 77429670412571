import styled from "styled-components";

export const ListContainer = styled.div`
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
`;

export const RowElementContainer = styled.div<{
  minWidth?: string;
  width?: string;
}>`
  display: flex;
  flex-direction: column;
  min-width: ${(props) => props.minWidth || "125px"};
  width: ${(props) => props.width || "null"};
  align-items: center;
`;

export const DateSeparator = styled.div`
  display: flex;
  align-items: center;
  width: 95%;
  height: 66px;
  padding-left: 24px;
  margin-top: 5px;
`;

export const ActivityListToolbarContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 2px;

  & > *:first-child {
    flex: 2;
    justify-content: flex-start;
  }
  & > *:last-child {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
  }
`;
