import { sendLogToCloudWatch } from "api";
import axios, { AxiosError, AxiosResponse } from "axios";
import { LogLevel, LogPayload } from "components/ErrorHandling/LogComponent";
import { useState } from "react";
import { config } from "./common";
import { environment, Environment } from "../environment";

interface Loading<B, P> {
  key: string;
  parameters: P;
  status: "Loading";
  body: B;
  startTime: Date;
}

interface Refresh<B, P, T> {
  key: string;
  parameters: P;
  status: "Loading";
  body: B;
  startTime: Date;
  prevData: T;
}

interface Success<B, P, T> {
  key: string;
  parameters: P;
  status: "Success";
  body: B;
  data: T;
  startTime: Date;
  endTime: Date;
}

interface Failure<B, P, E> {
  key: string;
  parameters: P;
  status: "Failure";
  body: B;
  error: E;
  startTime: Date;
  endTime: Date;
}

export type RemoteAction<B, P, T, E> =
  | Loading<B, P>
  | Success<B, P, T>
  | Failure<B, P, E>
  | Refresh<B, P, T>;

export type RemoteActionMethod = "POST" | "PUT" | "DELETE";

/* eslint-disable @typescript-eslint/no-unused-vars */
export type RemoteActionRequest<B, P, T> = {
  method: RemoteActionMethod;
  url: (parameters: P) => string;
};

export const loading = <B, P>(
  key: string,
  parameters: P,
  body: B
): Loading<B, P> => ({
  key,
  parameters,
  status: "Loading",
  body,
  startTime: new Date(),
});

export const refresh = <B, P, T>(
  key: string,
  parameters: P,
  body: B,
  prevData: T
): Refresh<B, P, T> => ({
  key,
  parameters,
  status: "Loading",
  body,
  prevData,
  startTime: new Date(),
});

export const success = <B, P, T>(
  key: string,
  parameters: P,
  body: B,
  data: T,
  startTime: Date
): Success<B, P, T> => ({
  key,
  parameters,
  status: "Success",
  body,
  data,
  startTime,
  endTime: new Date(),
});

export const failure = <B, P, E>(
  key: string,
  parameters: P,
  body: B,
  error: E,
  startTime: Date
): Failure<B, P, E> => ({
  key,
  parameters,
  status: "Failure",
  body,
  error,
  startTime,
  endTime: new Date(),
});

const replaceRequestWith =
  <B, P, T, E>(newState: RemoteAction<B, P, T, E>) =>
  (list: Array<RemoteAction<B, P, T, E>>) =>
    list.filter((r) => r.key !== newState.key).concat([newState]);

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const useRemoteAction = <B, P, T>(
  request: RemoteActionRequest<B, P, T>
) => {
  const [remoteActionList, setRemoteData] = useState<
    RemoteAction<B, P, T, AxiosError>[]
  >([]);

  const clearFinished = () =>
    setRemoteData((prevState) =>
      prevState.filter((r) => r.status !== "Success" && r.status !== "Failure")
    );

  const get = (key: string) => remoteActionList.find((r) => r.key === key);

  const send = (key: string, parameters: P, body: B) => {
    const prevRequest = remoteActionList.find((r) => r.key === key);

    if (prevRequest?.status === "Success" || prevRequest === undefined) {
      const nextState =
        prevRequest === undefined
          ? loading(key, parameters, body)
          : refresh(key, parameters, body, prevRequest.data);

      setRemoteData(replaceRequestWith<B, P, T, AxiosError>(nextState));

      axios({
        method: request.method,
        url: request.url(parameters),
        data: body,
        headers: config.headers,
      })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: AxiosResponse<any>) =>
          setRemoteData(
            replaceRequestWith(
              success(key, parameters, body, response.data, nextState.startTime)
            )
          )
        )
        .catch((error) => {
          if (environment() !== Environment.Development) {
            sendLogToCloudWatch(
              LogPayload(LogLevel.warn, JSON.stringify(error))
            );
          }
          setRemoteData(
            replaceRequestWith(
              failure(key, parameters, body, error, nextState.startTime)
            )
          );
        });
    }
  };

  return { remoteActionList, get, send, clearFinished };
};
