import { ScreenSize } from "../../App";

export const getColumnSize = (screenSize: ScreenSize) => {
  let columnSize;
  switch (screenSize) {
    case ScreenSize.NARROW:
      columnSize = {
        minWidth: "55px",
        width: "55px",
      };
      break;
    case ScreenSize.MEDIUM:
      columnSize = {
        minWidth: "80px",
        width: "80px",
      };
      break;
    case ScreenSize.LARGE:
      columnSize = {
        minWidth: "110px",
        width: "110px",
      };
      break;
    case ScreenSize.XLARGE:
      columnSize = {
        minWidth: "110px",
        width: "110px",
      };
      break;
    default:
      columnSize = {
        minWidth: "110px",
        width: "110px",
      };
  }
  return columnSize;
};
