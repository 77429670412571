import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as DeleteIconSVG } from "./icons/DeleteIcon.svg";
import {
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@vygruppen/spor-react";
import { useDisclosure } from "@chakra-ui/react";
import { BusActivity } from "../Activities/types";
import { useDeleteActivity } from "../Activities/useDeleteActivity";
import { CountryCode } from "../../api/locations";

const DeleteIcon = styled(DeleteIconSVG)`
  width: 30px;
`;

type DeleteActivityProps = {
  activity: BusActivity;
  refreshBusActivities: () => void;
  countryCode: CountryCode;
};

export const DeleteActivity: FunctionComponent<DeleteActivityProps> = ({
  activity,
  refreshBusActivities,
  countryCode,
}) => {
  const toast = useToast();

  const {
    deleteActivityById,
    getDeleteActivityRequest,
    clearFinishedAddActivityRequest,
  } = useDeleteActivity();

  const [requestId, setRequestId] = useState<string>("");
  const [responseCheck, setResponseCheck] = useState<number>(0);

  const checkResponse = (requestId: string) => {
    setResponseCheck(responseCheck + 1);
    setRequestId(requestId);
  };

  useEffect(() => {
    if (requestId !== "") {
      const res = getDeleteActivityRequest(requestId);
      if (res === undefined) {
        const toastText =
          "Det skjedde noe feil. Prøv igjen eller kontakt administrator";

        toast({ variant: "error", text: toastText });
        return;
      }
      if (res.status === "Success") {
        const toastText =
          countryCode === "SE"
            ? "Buss " + activity.vehicleId + " togs bort!"
            : "Buss " + activity.vehicleId + " ble fjernet!";
        toast({ variant: "success", text: toastText });
        clearFinishedAddActivityRequest();
        setRequestId("");
        refreshBusActivities();
      } else if (res.status === "Failure") {
        if (res.error.response?.status === 404) {
          const toastText =
            "Bussen er ikke lagt inn manuelt, eller ble ikke funnet. Det er ikke mulig å slette busser som ikke er lagt inn manuelt.";
          toast({ variant: "error", text: toastText });
        } else {
          const toastText =
            "Kunne ikke slette " +
            activity.vehicleId +
            ". Prøv igjen eller kontakt administrator.";
          toast({ variant: "error", text: toastText });
        }
        setRequestId("");
      } else {
        setTimeout(() => {
          setResponseCheck(responseCheck + 1);
        }, 100);
      }
    }
  }, [responseCheck]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleDelete = () => {
    onClose();
    const requestId = deleteActivityById(activity.activityId);
    checkResponse(requestId);
  };
  return (
    <>
      <IconButton
        variant={"ghost"}
        aria-label={countryCode === "SE" ? "Radera" : "Slett"}
        icon={<DeleteIcon />}
        onClick={onOpen}
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {countryCode === "SE" ? "Radera buss" : "Slett buss"}
          </ModalHeader>
          <ModalBody>
            <Stack spacing={5}>
              <Text>
                {countryCode === "SE"
                  ? `Är du säker på att du vill radera buss ${activity.vehicleId}?`
                  : `Er du sikker på at du vil slette buss ${activity.vehicleId}?`}
              </Text>
              <Stack flexDirection={"row"} spacing={5}>
                <Button variant={"secondary"} onClick={onClose}>
                  {countryCode === "SE" ? "Nej" : "Nei"}
                </Button>
                <Button variant={"primary"} onClick={handleDelete}>
                  Ja
                </Button>
              </Stack>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
