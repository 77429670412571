import axios, { AxiosResponse } from "axios";
import { LogPayload } from "components/ErrorHandling/LogComponent";
import { getVyBusBaseUrl } from "./common";
import { environment } from "environment";

const vyBusBaseUrl = getVyBusBaseUrl(environment());

const sendLogToCloudWatch = (payload: LogPayload): Promise<AxiosResponse> =>
  axios.post(`${vyBusBaseUrl}/frontend-logger/log`, payload);

export { sendLogToCloudWatch };
