import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { ReactComponent as BusIconSVG } from "features/ActivityList/icons/BusIcon.svg";
import { ReactComponent as BusIconManualSVG } from "features/ActivityList/icons/BusIconManual.svg";
import { ReactComponent as BusIconManualNarrowSVG } from "features/ActivityList/icons/BusIconManualNarrow.svg";
import { ReactComponent as BusIconNarrowSVG } from "features/ActivityList/icons/BusIconNarrow.svg";
import { Text } from "components/Text/StyledTexts";
import { Tooltip } from "@vygruppen/spor-react";

const BusIcon = styled(BusIconSVG)`
  height: 30px;
  width: 30px;
`;
const BusIconNarrow = styled(BusIconNarrowSVG)`
  height: 30px;
  width: 30px;
`;

const BusIconManual = styled(BusIconManualSVG)`
  height: 30px;
  width: 30px;
`;
const BusIconManualNarrow = styled(BusIconManualNarrowSVG)`
  height: 30px;
  width: 30px;
`;

const BusIDContainer = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  background-color: #fff;
  box-shadow: 2px 1px 1px #ececec;
  padding-right: 5px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
`;
const BusIDContainerNarrow = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #fff;
  box-shadow: 0px 2px 0px #ececec;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
`;

const BusIDIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const BusIDTextContainer = styled.div`
  display: flex;
  padding-left: 5px;
`;
const BusIDTextContainerNarrow = styled.div`
  display: flex;
  justify-content: center;
`;

interface BusIDProps {
  busId: React.ReactNode;
  driverName: string;
  manualBus: boolean;
  narrow: boolean;
}

export const BusIDNumber: FunctionComponent<BusIDProps> = ({
  busId,
  driverName,
  manualBus,
  narrow,
}: BusIDProps) => {
  return (
    <>
      <Tooltip
        placement={"right"}
        size={"lg"}
        content={
          <>
            <Text style={{ color: "#fff", fontWeight: "bold", margin: 0 }}>
              Sjåfør
            </Text>
            <Text style={{ color: "#fff", margin: 0 }}>
              {driverName ? driverName : "---"}
            </Text>
          </>
        }
      >
        {!narrow ? (
          <BusIDContainer>
            <BusIDIconContainer>
              {manualBus ? <BusIconManual /> : <BusIcon />}
            </BusIDIconContainer>
            <BusIDTextContainer>
              <Text style={{ marginLeft: "2px" }}>{busId}</Text>
            </BusIDTextContainer>
          </BusIDContainer>
        ) : (
          <BusIDContainerNarrow>
            <BusIDIconContainer>
              {manualBus ? <BusIconManualNarrow /> : <BusIconNarrow />}
            </BusIDIconContainer>
            <BusIDTextContainerNarrow>
              <Text style={{ marginLeft: "2px" }}>{busId}</Text>
            </BusIDTextContainerNarrow>
          </BusIDContainerNarrow>
        )}
      </Tooltip>
    </>
  );
};
