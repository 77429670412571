import React, { FunctionComponent, useState } from "react";
import { Header } from "features/ActivityList/Header";
import { Row } from "features/ActivityList/Row";
import {
  DateSeparator,
  RowElementContainer,
} from "features/ActivityList/style";
import { BusIDNumber } from "features/ActivityList/BusIDNumber";
import { Text } from "components/Text/StyledTexts";
import { compareAsc, format } from "date-fns";
import { nb } from "date-fns/locale";
import { BusActivity, TaskType } from "features/Activities/types";
import { ScrollableVertical } from "components/Layout/Scrollable";
import { ServiceLocation } from "../../api/locations";
import { safetyCheckEnabledForLocation } from "./LocationConfiguration";
import { getScreenSize, ScreenSize, useWindowDimensions } from "App";
import { DeleteActivity } from "./DeleteActivity";
import { getColumnSize } from "./Size";
import { CommentField } from "./CommentField";
import { TextLink, Checkbox } from "@vygruppen/spor-react";

interface IActivityListProps {
  activityList: BusActivity[];
  selectedLocation: ServiceLocation;
  showPreviousDay: boolean;
  markTaskAs: (
    activityId: string,
    taskType: TaskType,
    isComplete: boolean
  ) => void;
  searchText: string;
  refreshBusActivities: () => void;
}

const timeMissing = "--:--";

const ActivityList: FunctionComponent<IActivityListProps> = ({
  activityList,
  selectedLocation,
  showPreviousDay,
  markTaskAs,
  searchText,
  refreshBusActivities,
}: IActivityListProps) => {
  const [containsRundvask, setContainsRundvask] = useState<boolean>(false);
  const [activityListLength, setActivityListLength] = useState<number>(0);

  const sortedActivityList = activityList.sort((a, b) =>
    compareAsc(new Date(a.startTime), new Date(b.startTime))
  );
  const filteredActivityList =
    sortedActivityList.filter((buss) => buss.vehicleId.includes(searchText)) ||
    [];

  if (filteredActivityList.length !== activityListLength) {
    setActivityListLength(filteredActivityList.length);
    let containsRundvask = false;
    filteredActivityList.forEach((activity) => {
      if (activity.rundvask) {
        containsRundvask = true;
        return;
      }
    });
    setContainsRundvask(containsRundvask);
  }

  const { width } = useWindowDimensions();
  const screenSize = getScreenSize(width);
  const columnSize = getColumnSize(screenSize);

  const timeSlot = (from: string, to: string) => {
    const fromFormatted = from ? format(new Date(from), "HH:mm") : timeMissing;
    const toFormatted = to ? format(new Date(to), "HH:mm") : timeMissing;

    return getScreenSize(width) <= ScreenSize.MEDIUM
      ? `${fromFormatted}\n${toFormatted}`
      : `${fromFormatted} - ${toFormatted}`;
  };

  let prevDate = "";
  const setPrevDate = (newDate?: string) => {
    prevDate = newDate || "";
  };

  const getDay = (date: string) => {
    return format(new Date(date), "dd");
  };

  const capitalize = (s: string) => {
    return `${s.charAt(0).toLocaleUpperCase()}${s.slice(1)}`;
  };

  const dateSeparator = (date: string) => {
    return (
      <DateSeparator>
        <Text style={{ fontWeight: "bold", fontSize: "18px" }}>
          {capitalize(
            format(new Date(date), "EEEE do LLLL", {
              locale: nb,
            }).toString()
          )}
        </Text>
      </DateSeparator>
    );
  };
  const completeAllIncompleteTaskFor = (activity: BusActivity) => {
    activity.tasks.forEach((task) => {
      if (!task.isCompleted) {
        markTaskAs(activity.activityId, task.taskType, true);
      }
    });
  };

  const hasActiveTaskRequests = (activity: BusActivity) =>
    activity.tasks.some((t) => t.status === "Loading");

  const createCheckbox = (activity: BusActivity, taskType: TaskType) => {
    const task = activity.tasks.find((t) => t.taskType === taskType);

    if (task == null) {
      return null;
    }

    return (
      <Checkbox
        onChange={() =>
          markTaskAs(activity.activityId, taskType, !task.isCompleted)
        }
        isChecked={task.isCompleted}
        name={`${taskType}-${activity.activityId}`}
        disabled={task.status == "Loading"}
      />
    );
  };

  interface IActivityRow {
    activity: BusActivity;
    selectedLocation: ServiceLocation;
  }

  const ActivityRow = ({ activity, selectedLocation }: IActivityRow) => (
    <Row key={`${activity.vehicleId}${activity.startTime}`}>
      <RowElementContainer
        minWidth={screenSize < ScreenSize.LARGE ? "70px" : "125px"}
        style={{
          width: screenSize < ScreenSize.LARGE ? "70px" : "null",
        }}
      >
        <Text isLight isLarge>
          {timeSlot(activity.startTime, activity.endTime)}
        </Text>
      </RowElementContainer>
      <RowElementContainer
        minWidth={screenSize === ScreenSize.NARROW ? "90px" : "140px"}
        width={screenSize === ScreenSize.NARROW ? "90px" : "140px"}
        style={{
          flexDirection: "row",
        }}
      >
        <div
          style={{
            margin: screenSize === ScreenSize.NARROW ? "1px" : "10px",
          }}
        />
        <BusIDNumber
          driverName={activity.lastDriverName}
          busId={activity.vehicleId}
          manualBus={activity.addedManually}
          narrow={screenSize === ScreenSize.NARROW}
        />
      </RowElementContainer>
      <RowElementContainer
        minWidth={columnSize.minWidth}
        width={columnSize.width}
      >
        {activity.activityType.taskTypes.includes("TANK") &&
          createCheckbox(activity, "TANK")}
      </RowElementContainer>
      <RowElementContainer
        minWidth={columnSize.minWidth}
        width={columnSize.width}
      >
        {activity.activityType.taskTypes.includes("INTERNAL_WASH") &&
          createCheckbox(activity, "INTERNAL_WASH")}
      </RowElementContainer>
      <RowElementContainer
        minWidth={columnSize.minWidth}
        width={columnSize.width}
      >
        {activity.activityType.taskTypes.includes("EXTERNAL_WASH") &&
          createCheckbox(activity, "EXTERNAL_WASH")}
      </RowElementContainer>
      <RowElementContainer
        minWidth={columnSize.minWidth}
        width={columnSize.width}
      >
        {activity.activityType.taskTypes.includes("COOLANT") &&
          createCheckbox(activity, "COOLANT")}
      </RowElementContainer>
      <RowElementContainer
        minWidth={columnSize.minWidth}
        width={columnSize.width}
      >
        {activity.activityType.taskTypes.includes("WASHER_FLUID") &&
          createCheckbox(activity, "WASHER_FLUID")}
      </RowElementContainer>
      <RowElementContainer
        minWidth={columnSize.minWidth}
        width={columnSize.width}
      >
        {activity.activityType.taskTypes.includes("OIL") &&
          createCheckbox(activity, "OIL")}
      </RowElementContainer>
      <RowElementContainer
        minWidth={columnSize.minWidth}
        width={columnSize.width}
      >
        {activity.activityType.taskTypes.includes("ADBLUE") &&
          createCheckbox(activity, "ADBLUE")}
      </RowElementContainer>
      {safetyCheckEnabledForLocation(selectedLocation) && (
        <RowElementContainer
          minWidth={columnSize.minWidth}
          width={columnSize.width}
        >
          {activity.activityType.taskTypes.includes("SAFETY_CHECK") &&
            createCheckbox(activity, "SAFETY_CHECK")}
        </RowElementContainer>
      )}
      {containsRundvask && (
        <RowElementContainer
          minWidth={columnSize.minWidth}
          width={columnSize.width}
        >
          {activity.rundvask && createCheckbox(activity, "RUNDVASK")}
        </RowElementContainer>
      )}
      <RowElementContainer
        minWidth={columnSize.minWidth}
        width={columnSize.width}
      >
        <TextLink
          variant={"primary"}
          onClick={() => completeAllIncompleteTaskFor(activity)}
        >
          {selectedLocation.countryCode === "SE" ? "Välj alle" : "Velg alle"}
        </TextLink>
      </RowElementContainer>
      <RowElementContainer minWidth={"50px"}>
        <CommentField
          activity={activity}
          refreshActivityList={refreshBusActivities}
          countryCode={selectedLocation.countryCode}
        />
      </RowElementContainer>
      <RowElementContainer
        minWidth={columnSize.minWidth}
        width={columnSize.width}
      >
        {activity.addedManually && (
          <DeleteActivity
            activity={activity}
            refreshBusActivities={refreshBusActivities}
            countryCode={selectedLocation.countryCode}
          />
        )}
      </RowElementContainer>
    </Row>
  );

  return (
    <>
      <Header
        selectedLocation={selectedLocation}
        containsRundvask={containsRundvask}
      />
      {filteredActivityList.length === 0 ? (
        <Text style={{ marginTop: "15px" }}>
          Fant ingen busser med nummer {searchText}
        </Text>
      ) : (
        <ScrollableVertical>
          {setPrevDate(filteredActivityList[0]?.startTime)}
          {showPreviousDay && dateSeparator(filteredActivityList[0]?.startTime)}
          {filteredActivityList.map((activity) => (
            <React.Fragment key={activity.activityId}>
              {getDay(prevDate) === getDay(activity.startTime) ? (
                <ActivityRow
                  activity={activity}
                  selectedLocation={selectedLocation}
                />
              ) : (
                <>
                  {dateSeparator(activity.startTime)}
                  <ActivityRow
                    activity={activity}
                    selectedLocation={selectedLocation}
                  />
                </>
              )}
              {setPrevDate(activity.startTime)}
            </React.Fragment>
          ))}
        </ScrollableVertical>
      )}
    </>
  );
};

export { ActivityList };
