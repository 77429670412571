import React, { FunctionComponent } from "react";
import { signOut } from "api/login";
import { Text, TextLink } from "@vygruppen/spor-react";
import { ServiceLocation } from "../../api/locations";

interface LogOutProps {
  selectedLocation: ServiceLocation | null;
}
const LogOut: FunctionComponent<LogOutProps> = ({ selectedLocation }) => {
  return (
    <Text>
      <TextLink onClick={signOut} variant="secondary">
        {selectedLocation && selectedLocation.countryCode === "SE"
          ? "Logga ut"
          : "Logg ut"}
      </TextLink>
    </Text>
  );
};

export default LogOut;
