import { RemoteDataGetRequest } from "./remoteData";
import { getVyBusBaseUrl } from "./common";
import { environment } from "environment";
import { RemoteActionRequest } from "./remoteAction";
import { format } from "date-fns";
import {
  AddCommentDTO,
  AddNewActivityRequest,
  BusActivityCollection,
} from "../features/Activities/types";

const vyBusBaseUrl = getVyBusBaseUrl(environment());

export const getActivitiesForLocation = (
  location: string,
  includeActivitiesFromPreviousDay: boolean
): RemoteDataGetRequest<BusActivityCollection> => {
  if (includeActivitiesFromPreviousDay) {
    const previousDay = new Date();
    previousDay.setDate(previousDay.getDate() - 1);
    previousDay.setHours(0, 0, 1);
    const previousDayFormatted = format(previousDay, "yyyy-MM-dd'T'HH:mm:ss");

    return {
      url: `${vyBusBaseUrl}/serviceplan-backend/locations/${location}/activities?from=${previousDayFormatted}`,
    };
  }

  return {
    url: `${vyBusBaseUrl}/serviceplan-backend/locations/${location}/activities`,
  };
};

export interface PostActivityParameters {
  param: null;
}

export const postActivity: RemoteActionRequest<
  AddNewActivityRequest,
  PostActivityParameters,
  void
> = {
  method: "POST",
  url: () => `${vyBusBaseUrl}/serviceplan-backend/activities/`,
};

export interface DeleteActivityParameters {
  activityId: string;
}

export const deleteActivity: RemoteActionRequest<
  void,
  DeleteActivityParameters,
  void
> = {
  method: "DELETE",
  url: ({ activityId }: DeleteActivityParameters) =>
    `${vyBusBaseUrl}/serviceplan-backend/activities/${activityId}`,
};

export interface PutTaskParameters {
  activityId: string;
  taskType: string;
}

export const putTask: RemoteActionRequest<void, PutTaskParameters, void> = {
  method: "PUT",
  url: ({ activityId, taskType }: PutTaskParameters) =>
    `${vyBusBaseUrl}/serviceplan-backend/activities/${activityId}/tasks/${taskType}`,
};

export interface DeleteTaskParameters {
  activityId: string;
  taskType: string;
}

export const deleteTask: RemoteActionRequest<void, DeleteTaskParameters, void> =
  {
    method: "DELETE",
    url: ({ activityId, taskType }: DeleteTaskParameters) =>
      `${vyBusBaseUrl}/serviceplan-backend/activities/${activityId}/tasks/${taskType}`,
  };

export interface UpdateCommentParameters {
  activityId: string;
}

export const putComment: RemoteActionRequest<
  AddCommentDTO,
  UpdateCommentParameters,
  void
> = {
  method: "PUT",
  url: ({ activityId }: UpdateCommentParameters) =>
    `${vyBusBaseUrl}/serviceplan-backend/activities/${activityId}/comment`,
};
