import React from "react";
import styled from "styled-components";
import { ReactComponent as editSVG } from "features/TopBar/icons/edit.svg";
import { ServiceLocation } from "api/locations";
import { removeSelectedLocation } from "api/serviceplanLocalStrorage";
import { Button } from "@vygruppen/spor-react";

const EditIcon = styled(editSVG)`
  width: 1rem;
  cursor: pointer;
  margin-right: 10px;
`;

type LocationProps = {
  onSetNewLocation: (newLocation: ServiceLocation | null) => void;
  selectedLocation: ServiceLocation;
};

export const EditLocation: React.FC<LocationProps> = ({
  onSetNewLocation,
  selectedLocation,
}) => {
  return (
    <Button
      variant="tertiary"
      onClick={() => {
        onSetNewLocation(null);
        removeSelectedLocation();
      }}
      leftIcon={<EditIcon />}
    >
      {selectedLocation.countryCode === "SE" ? "Välj hall" : "Bytt hall"}
    </Button>
  );
};
