import React, { useState } from "react";
import { useRemoteDataGet } from "api/remoteData";
import { getAllServiceLocations, ServiceLocation } from "api/locations";
import { Loading } from "components/Loading/Loading";
import { ErrorMessage } from "components/ErrorHandling/ErrorMessage";
import {
  Button,
  Card,
  Heading,
  InfoSelect,
  Item,
  Text,
} from "@vygruppen/spor-react";
import styled from "styled-components";

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const CardStyle = {
  width: "350px",
  height: "300px",
  backgroundColor: "rgba(255,255,255,0.5)",
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "max(0px, (calc(100vh - 400px)/2 - 100px))",
};

const SelectButtonStyle = {
  width: "calc(100% - 8px)",
};

type LocationSelectorProps = {
  onSetNewLocation: (newLocation: ServiceLocation) => void;
};

export const LocationSelector: React.FC<LocationSelectorProps> = ({
  onSetNewLocation,
}) => {
  const { remoteData } = useRemoteDataGet(getAllServiceLocations());
  const [selectedItem, setSelectedItem] = useState<ServiceLocation | null>(
    null
  );

  switch (remoteData.status) {
    case "NotAsked":
    case "Loading":
      return <Loading width={"60%"} />;
    case "Refreshing":
    case "ShouldRefresh":
    case "Failure":
      return <ErrorMessage />;
    case "Success":
      return (
        <ContentContainer>
          <Card colorScheme={"white"} padding={4} style={{ ...CardStyle }}>
            <Heading as={"h3"} variant={"md"}>
              Velg vaskehall
            </Heading>
            <br />
            <Text variant={"sm"}>
              Se planen for en vaskehall ved å velge den fra listen:
            </Text>
            <br />
            <InfoSelect
              width={"280px"}
              label={""}
              onChange={(id) => {
                remoteData.data.locations.forEach((loc) => {
                  if (loc.id === id) {
                    setSelectedItem(loc);
                    return;
                  }
                });
              }}
              items={remoteData.data.locations}
              /* eslint-disable-next-line react/no-children-prop */
              children={(item) => <Item key={item.id}>{item.name}</Item>}
              placeholder={
                selectedItem ? selectedItem.name : "Velg en vaskehall.."
              }
              variant={"floating"}
            />
            <br />
            <Button
              onClick={() => selectedItem && onSetNewLocation(selectedItem)}
              isDisabled={selectedItem === null}
              style={SelectButtonStyle}
            >
              Lagre
            </Button>
          </Card>
        </ContentContainer>
      );
  }
};
