import { v4 as uuidv4 } from "uuid";

export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const COGNITO_STATE_PARAM = "COGNITO_STATE_PARAM";
export const REFRESH_TOKEN = "REFRESH_TOKEN";

export const SELECTED_LOCATION = "SELECTED_LOCATION";

export const getServiceplanToken = (): string | null =>
  localStorage.getItem(ACCESS_TOKEN);

export const getCognitoState = (): string | null =>
  localStorage.getItem(COGNITO_STATE_PARAM);

export const clearAllButLocationInLocalStorage = async (): Promise<void> => {
  const locationInLS = await getSelectedLocation();
  await localStorage.clear();
  locationInLS && localStorage.setItem(SELECTED_LOCATION, locationInLS);
};

export const createCognitoState = (): void => {
  localStorage.setItem(COGNITO_STATE_PARAM, uuidv4());
};

export const getSelectedLocation = (): string | null =>
  localStorage.getItem(SELECTED_LOCATION);

export const removeSelectedLocation = (): void =>
  localStorage.removeItem(SELECTED_LOCATION);
