import { getCognitoBaseUrl, getCognitoClientId } from "features/Login/Login";
import axios, {
  AxiosPromise,
  InternalAxiosRequestConfig,
  AxiosResponse,
} from "axios";
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  getServiceplanToken,
  clearAllButLocationInLocalStorage,
} from "./serviceplanLocalStrorage";

const config = {
  headers: {
    Authorization: `Bearer ${getServiceplanToken()}`,
  },
};

const refreshCognitoTokens = (refreshToken: string): AxiosPromise =>
  axios({
    method: "POST",
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
    url: `${getCognitoBaseUrl()}/oauth2/token`,
    data: `grant_type=refresh_token&client_id=${getCognitoClientId()}&refresh_token=${refreshToken}`,
  });

axios.interceptors.request.use(
  (interceptConfig: InternalAxiosRequestConfig) => {
    // Declare any interception before requests are sent
    config.headers.Authorization = `Bearer ${getServiceplanToken()}`;

    return interceptConfig;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const refresh_token = localStorage.getItem(REFRESH_TOKEN);

    if (
      error.response?.status === 401 &&
      error.config &&
      !error.config._retry &&
      (!refresh_token || refresh_token === "")
    ) {
      signOut();
      return;
    }

    if (
      error.response?.status === 401 &&
      error.config &&
      !error.config._retry &&
      refresh_token
    ) {
      // eslint-disable-next-line consistent-return
      return new Promise((resolve, reject) => {
        refreshCognitoTokens(refresh_token)
          .then((response: AxiosResponse<{ access_token: string }>) => {
            if (response.data) {
              localStorage.setItem(ACCESS_TOKEN, response.data.access_token);
            }
            error.config._retry = true;
            error.config.headers.Authorization = `Bearer ${getServiceplanToken()}`;

            resolve(axios(error.config));
          })
          .catch((interceptError) => {
            reject(interceptError);
          });
      });
    }
    // eslint-disable-next-line consistent-return
    return Promise.reject(error);
  }
);

const signOut: () => void = () => {
  clearAllButLocationInLocalStorage();
  const initiateLogoutUrl = `${getCognitoBaseUrl()}/logout?client_id=${getCognitoClientId()}&logout_uri=${
    window.location.origin
  }`;
  window.location.assign(initiateLogoutUrl);
};

export { refreshCognitoTokens, signOut };
