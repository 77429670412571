import { Environment } from "environment";
import { getServiceplanToken } from "./serviceplanLocalStrorage";

export const getVyBusBaseUrl = (env: Environment): string => {
  switch (env) {
    case Environment.Development:
      return "https://services.test.vybus.vydev.io";
    case Environment.Test:
      return "https://services.test.vybus.vydev.io";
    case Environment.Stage:
      return "https://services.stage.vybus.vydev.io";
    case Environment.Production:
      return "https://services.vybus.vydev.io";
  }
};

export const config = {
  headers: {
    Authorization: `Bearer ${getServiceplanToken()}`,
  },
};
