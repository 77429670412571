import { ServiceLocation } from "../../api/locations";

const safetyCheckLocations = [
  "OESTERSUND",
  "KUNGAELV",
  "BENGTSFORS",
  "FROELAND",
  "VAENERSBORG",
  "MELLERUD",
  "BENGTFORS",
  "AAMAAL",
];

/**
 * Not all locations should display the safety check checkbox. For now, only swedish locations should display them.
 * This function exists to prevent this logic from being scattered around in various components.
 */
export const safetyCheckEnabledForLocation = (
  location: ServiceLocation
): boolean => {
  return safetyCheckLocations.includes(location.id);
};

export const activityTypeSelectionIsEnabledForLocation = (
  location: ServiceLocation
): boolean => {
  return safetyCheckLocations.includes(location.id);
};
