import styled, { css } from "styled-components";

const resetMarginPadding = css`
  margin: 0;
  padding: 0;
`;
export const MainTitle = styled.p`
  font-size: 35px;
  color: white;
  font-variant: small-caps;
  text-align: center;
`;

export const SubTitle = styled.h4`
  ${resetMarginPadding};
  font-size: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.textColor};
  word-break: break-word;
`;

export const LoaderContainer = styled.div`
  width: 60%;
  margin: 20rem auto;
`;
