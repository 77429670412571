import { useRemoteAction } from "../../api/remoteAction";
import { putComment } from "../../api/activities";
import { v4 as uuidv4 } from "uuid";

export const useUpdateComment = () => {
  const {
    get: getUpdateCommentRequest,
    send: sendUpdateCommentRequest,
    clearFinished: clearFinishedUpdateCommentRequest,
  } = useRemoteAction(putComment);

  const updateComment = (activityId: string, comment: string): string => {
    const requestUUID = uuidv4();
    sendUpdateCommentRequest(
      requestUUID,
      { activityId: activityId },
      { comment: comment }
    );
    return requestUUID;
  };

  return {
    updateComment,
    getUpdateCommentRequest,
    clearFinishedUpdateCommentRequest,
  };
};
