export enum LogLevel {
  error = "error",
  warn = "warn",
  info = "info",
}

export type LogPayload = {
  logLevel: LogLevel;
  source: "serviceplan-frontend";
  data: {
    username: string | null;
    componentStack: string;
  };
  message: string | undefined;
};

export const LogPayload = (
  logLevel: LogLevel,
  message: string,
  componentStack?: string
): LogPayload => ({
  logLevel,
  source: "serviceplan-frontend",
  data: {
    username: "Not Available",
    componentStack: componentStack || "Not Available",
  },
  message,
});
