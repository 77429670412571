import { getVyBusBaseUrl } from "api/common";
import { environment } from "environment";
import { RemoteDataGetRequest } from "api/remoteData";

export type ServiceLocationCollection = {
  locations: ServiceLocation[];
};

export type ServiceLocation = {
  id: string;
  name: string;
  countryCode: CountryCode;
};

export type CountryCode = "SE" | "NO";

const vyBusBaseUrl = getVyBusBaseUrl(environment());

export const getAllServiceLocations =
  (): RemoteDataGetRequest<ServiceLocationCollection> => ({
    url: `${vyBusBaseUrl}/serviceplan-backend/locations`,
  });
