import { BusActivity } from "../Activities/types";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Input, CardSelect } from "@vygruppen/spor-react";
import { useUpdateComment } from "../Activities/useUpdateComment";
import { getScreenSize, ScreenSize, useWindowDimensions } from "../../App";
import { ReactComponent as CommentButtonIconSVG } from "./icons/CommentIcon.svg";
import styled from "styled-components";
import { CountryCode } from "../../api/locations";

const CommentFieldContainer = styled.div`
  min-width: 300px;
`;

const CommentButtonContainer = styled.div`
  width: 40px;
  margin: auto;
`;

const CommentButtonIcon = styled(CommentButtonIconSVG)`
  width: 30px;
  height: 30px;
`;

type CommentFieldProps = {
  activity: BusActivity;
  refreshActivityList: () => void;
  countryCode: CountryCode;
};

export const CommentField: FunctionComponent<CommentFieldProps> = ({
  activity,
  refreshActivityList,
  countryCode,
}) => {
  const [comment, setComment] = useState(
    activity.comment === null ? "" : activity.comment
  );
  const [previousComment, setPreviousComment] = useState(comment);

  const [requestId, setRequestId] = useState<string>("");
  const [responseCheck, setResponseCheck] = useState<number>(0);

  const {
    updateComment,
    getUpdateCommentRequest,
    clearFinishedUpdateCommentRequest,
  } = useUpdateComment();

  const checkResponse = (requestId: string) => {
    setResponseCheck(responseCheck + 1);
    setRequestId(requestId);
  };

  useEffect(() => {
    if (requestId !== "") {
      const res = getUpdateCommentRequest(requestId);
      if (res === undefined) {
        console.log(
          "Something unexpected happened when trying to update the comment."
        );
        return;
      }
      if (res.status === "Success") {
        clearFinishedUpdateCommentRequest();
        setRequestId("");
        refreshActivityList();
      } else if (res.status === "Failure") {
        console.log("The request to add a comment returned with an error.");
        setRequestId("");
      } else {
        setTimeout(() => {
          setResponseCheck(responseCheck + 1);
        }, 100);
      }
    }
  }, [responseCheck]);

  const { width } = useWindowDimensions();
  return (
    <>
      {getScreenSize(width) <= ScreenSize.LARGE.valueOf() ? (
        <CommentButtonContainer>
          <CardSelect
            aria-label={"Kommentar"}
            variant="ghost"
            size="md"
            label=""
            icon={<CommentButtonIcon />}
            withChevron={false}
            onToggle={(isOpen: boolean) => {
              if (!isOpen && comment !== previousComment) {
                const requestId = updateComment(activity.activityId, comment);
                checkResponse(requestId);
                setPreviousComment(comment);
              }
            }}
          >
            <Input
              label={
                countryCode === "SE"
                  ? "Lägg till en kommentar"
                  : "Legg til en kommentar"
              }
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </CardSelect>
        </CommentButtonContainer>
      ) : (
        <CommentFieldContainer>
          <Input
            label={
              countryCode === "SE"
                ? "Lägg till en kommentar"
                : "Legg til en kommentar"
            }
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            onBlur={() => {
              if (comment !== previousComment) {
                const requestId = updateComment(activity.activityId, comment);
                checkResponse(requestId);
              }
            }}
          />
        </CommentFieldContainer>
      )}
    </>
  );
};
