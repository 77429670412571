import React, { FunctionComponent } from "react";
import styled from "styled-components";

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 100%;
  width: fit-content;
  height: 66px;
  background-color: #fff;
  padding-left: 24px;
  margin-top: 5px;
  border: 1px solid #dce4ef;

  &:last-child {
    margin-bottom: 20px;
  }
`;

interface IBusListRowProps {
  children: React.ReactNode;
}

export const Row: FunctionComponent<IBusListRowProps> = ({
  children,
}: IBusListRowProps) => <RowContainer>{children}</RowContainer>;
