import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { ReactComponent as VYLogoSVG } from "features/TopBar/icons/VyLogo.svg";
import Clock from "features/TopBar/Clock";
import LogOut from "features/TopBar/LogOut";
import { EditLocation } from "features/TopBar/EditLocation";
import { ServiceLocation } from "api/locations";
import { BusIconDescription } from "./BusIconDescription";
import { Checkbox } from "@vygruppen/spor-react";

const TopBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 88px;
  min-height: 88px;
  background-color: #fff;
  box-shadow: ${({ theme }): string => theme.elevation1};

  & > *:last-child {
    justify-content: flex-end;
  }
`;

const VyLogoContainer = styled.div`
  display: flex;
  margin-left: 20px;
  flex-grow: 0.25;
  flex-basis: 0;
`;

const VyLogo = styled(VYLogoSVG)`
  width: 78px;
`;

const TimeAndPlaceContainer = styled.div`
  line-height: 1.5rem;
  font-size: 1.125rem;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  flex-direction: row;
  justify-items: flex-end;
  align-items: center;
  padding-right: 10px;

  & > * {
    margin-right: 10px;
  }
`;

const Bold = styled.span`
  font-weight: bold;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-grow: 0.75;
  flex-basis: 0;
  justify-content: center;
  align-items: center;
`;

export type TopBarProps = {
  selectedLocation: ServiceLocation | null;
  onSetNewLocation: (newLocation: ServiceLocation | null) => void;
  showPreviousDay: boolean;
  setShowPreviousDay: React.Dispatch<React.SetStateAction<boolean>>;
};

export const TopBar: FunctionComponent<TopBarProps> = ({
  selectedLocation,
  onSetNewLocation,
  showPreviousDay,
  setShowPreviousDay,
}) => {
  return (
    <TopBarContainer>
      <VyLogoContainer>
        <VyLogo />
      </VyLogoContainer>
      <InfoContainer>
        {selectedLocation && (
          <BusIconDescription selectedLocation={selectedLocation} />
        )}
      </InfoContainer>
      <TimeAndPlaceContainer>
        {selectedLocation && <Bold>{selectedLocation.name}</Bold>}
        <Clock />
        {selectedLocation && (
          <Checkbox
            margin="0"
            style={{ marginTop: "3px" }}
            onChange={() => {
              setShowPreviousDay(!showPreviousDay);
            }}
            checked={showPreviousDay}
            name=""
          >
            {selectedLocation.countryCode === "SE"
              ? "Visa utökad historik"
              : "Vis utvidet historikk"}
          </Checkbox>
        )}
      </TimeAndPlaceContainer>
      <ButtonsContainer>
        {selectedLocation && (
          <EditLocation
            onSetNewLocation={onSetNewLocation}
            selectedLocation={selectedLocation}
          />
        )}
        <LogOut selectedLocation={selectedLocation} />
      </ButtonsContainer>
    </TopBarContainer>
  );
};
