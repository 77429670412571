import { deleteTask, putTask } from "api/activities";
import { useRemoteAction } from "api/remoteAction";
import { compareDesc } from "date-fns";
import { TaskType } from "features/Activities/types";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const useActivityTasks = () => {
  const {
    get: getCompletedTaskRequest,
    send: sendMarkTaskAsComplete,
    clearFinished: clearFinishedMarkTaskAsCompleteRequests,
  } = useRemoteAction(putTask);
  const {
    get: getIncompleteTaskRequest,
    send: sendMarkTaskAsIncomplete,
    clearFinished: clearFinishedMarkTaskAsIncompleteRequests,
  } = useRemoteAction(deleteTask);

  const markTaskAsComplete = (activityId: string, taskType: TaskType) =>
    sendMarkTaskAsComplete(activityId + taskType, {
      activityId,
      taskType,
    });

  const markTaskAsIncomplete = (activityId: string, taskType: TaskType) =>
    sendMarkTaskAsIncomplete(activityId + taskType, {
      activityId,
      taskType,
    });

  const markTaskAs = (
    activityId: string,
    taskType: TaskType,
    isComplete: boolean
  ) =>
    isComplete
      ? markTaskAsComplete(activityId, taskType)
      : markTaskAsIncomplete(activityId, taskType);

  const clearFinishedRequests = () => {
    clearFinishedMarkTaskAsCompleteRequests();
    clearFinishedMarkTaskAsIncompleteRequests();
  };

  const maybeGetTime = (date?: Date) => date?.getTime() || 0;

  const getLastStartedTaskRequest = (activityId: string, taskType: TaskType) =>
    [
      {
        type: "Complete",
        request: getCompletedTaskRequest(activityId + taskType),
      },
      {
        type: "Incomplete",
        request: getIncompleteTaskRequest(activityId + taskType),
      },
    ].sort((a, b) =>
      compareDesc(
        maybeGetTime(a?.request?.startTime),
        maybeGetTime(b?.request?.startTime)
      )
    )[0];

  return { markTaskAs, clearFinishedRequests, getLastStartedTaskRequest };
};
