import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as plusSvg } from "features/TopBar/icons/plus.svg";
import { ReactComponent as infoSvg } from "features/ActivityListToolbar/icons/info.svg";
import { TimeValue } from "@react-types/datepicker";
import {
  CalendarDate,
  DateValue,
  now,
  ZonedDateTime,
  Time,
} from "@internationalized/date";
import {
  FormControl,
  Input,
  FormErrorMessage,
  DatePicker,
  TimePicker,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  StaticAlert,
  Checkbox,
  InfoSelect,
  Item,
  IconButton,
  Tooltip,
} from "@vygruppen/spor-react";
import { useDisclosure } from "@chakra-ui/react";
import {
  ActivityType,
  ActivityTypes,
  AddBusFormData,
  getActivityTypeById,
  TaskNames,
} from "../Activities/types";
import { activityTypeSelectionIsEnabledForLocation } from "../ActivityList/LocationConfiguration";
import { CountryCode, ServiceLocation } from "../../api/locations";

const AddIcon = styled(plusSvg)`
  width: 1rem;
  cursor: pointer;
  margin-right: 10px;
`;

const InfoIcon = styled(infoSvg)``;

const InfoButtonStyle = {
  height: "fit-content",
  marginTop: "auto",
};

const ActivityTypeTooltipContent = (
  activityType: ActivityType,
  countryCode: CountryCode
) => {
  return (
    <div style={{ padding: "5px" }}>
      <p>{countryCode === "SE" ? "Inkluderar:" : "Inkluderer:"} </p>
      {activityType.taskTypes.map((taskType) => {
        return (
          <p key={taskType}>
            -{" "}
            {countryCode === "SE"
              ? TaskNames[taskType].name_se
              : TaskNames[taskType].name_no}
          </p>
        );
      })}
    </div>
  );
};

type AddBusManuallyProps = {
  onAddBusManually: (busObj: AddBusFormData) => void;
  resetForm: boolean;
  setResetForm: React.Dispatch<React.SetStateAction<boolean>>;
  selectedLocation: ServiceLocation;
};

export const AddBusManually: React.FC<AddBusManuallyProps> = ({
  onAddBusManually,
  resetForm,
  setResetForm,
  selectedLocation,
}) => {
  const [selectedActivityType, setSelectedActivityType] =
    useState<ActivityType>(ActivityTypes.VASK_TANK);
  const [busId, setBusId] = useState<string | null>(null);
  const dateTimeNow = now("Europe/Oslo");
  const dateNow = new CalendarDate(
    dateTimeNow.year,
    dateTimeNow.month,
    dateTimeNow.day
  );

  const initStartTime = new Time(dateTimeNow.hour, dateTimeNow.minute);
  const initEndTime = initStartTime.add({ minutes: 20 });

  const [startDate, setStartDate] = useState<CalendarDate>(dateNow);
  const [startTime, setStartTime] = useState<Time>(initStartTime);
  const [endTime, setEndTime] = useState<Time>(initEndTime);
  const [rundvask, setRundvask] = useState<boolean>(false);
  const [showWarning, setShowWarning] = useState<boolean>(false);

  const handleOnSubmit = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (event.type !== "submit") {
      return;
    } else if (busId === null || busId === "" || endTime < startTime) {
      setShowWarning(true);
    } else {
      const startDateTime = new ZonedDateTime(
        startDate.year,
        startDate.month,
        startDate.day,
        "Europe/Oslo",
        60 * 60 * 1000,
        startTime.hour,
        startTime.minute
      );

      const endDateTime = new ZonedDateTime(
        startDate.year,
        startDate.month,
        startDate.day,
        "Europe/Oslo",
        60 * 60 * 1000,
        endTime.hour,
        endTime.minute
      );

      const busObject = {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        busNr: busId!,
        activityType: selectedActivityType.id,
        startDate: startDate.toString(),
        startTime: startDateTime.toString(),
        endTime: endDateTime.toString(),
        rundvask: rundvask,
      };

      onAddBusManually(busObject);
      onClose();
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleModalOpen = () => {
    if (resetForm) {
      const dateTimeNow = now("Europe/Oslo");
      const dateNow = new CalendarDate(
        dateTimeNow.year,
        dateTimeNow.month,
        dateTimeNow.day
      );
      const initStartTime = new Time(dateTimeNow.hour, dateTimeNow.minute);
      const initEndTime = initStartTime.add({ minutes: 20 });

      setBusId(null);
      setShowWarning(false);
      setStartTime(initStartTime);
      setStartDate(dateNow);
      setEndTime(initEndTime);
      setRundvask(false);
      setSelectedActivityType(ActivityTypes.VASK_TANK);
    } else {
      setResetForm(true);
    }
    onOpen();
  };
  const handleModalClose = () => {
    onClose();
    setShowWarning(false);
  };

  return (
    <>
      <Button
        leftIcon={<AddIcon />}
        variant="secondary"
        onClick={handleModalOpen}
        size="md"
      >
        {selectedLocation.countryCode === "SE"
          ? "Lägg till buss"
          : "Legg til buss"}
      </Button>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={handleModalClose}
        trapFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {" "}
            {selectedLocation.countryCode === "SE"
              ? "Lägg till ny buss"
              : "Legg til ny buss"}
          </ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleOnSubmit}>
            <ModalBody>
              <Stack spacing={2}>
                {showWarning && (
                  <StaticAlert variant="error">
                    {selectedLocation.countryCode === "SE"
                      ? "Se till att alla obligatoriska fält är ifyllda"
                      : "Sørg for at alle obligatoriske felt er fylt ut"}
                  </StaticAlert>
                )}
                <FormControl
                  isInvalid={busId === "" || (busId === null && showWarning)}
                  style={{ marginBottom: "20px" }}
                >
                  <Input
                    label="Buss id"
                    id="busId"
                    value={busId === null ? "" : busId}
                    onChange={(e) => setBusId(e.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <DatePicker
                    id="startDate"
                    label={
                      selectedLocation.countryCode === "SE" ? "Datum" : "Dato"
                    }
                    variant="base"
                    value={startDate}
                    onChange={(date: DateValue | null) => {
                      if (date !== null) {
                        const newStartDate = startDate.set({
                          year: date.year,
                          month: date.month,
                          day: date.day,
                        });
                        setStartDate(newStartDate);
                      }
                    }}
                  />
                </FormControl>
                <Stack flexDirection="row">
                  <FormControl>
                    <TimePicker
                      id="startTime"
                      label="Starttid"
                      minuteInterval={15}
                      value={startTime}
                      onChange={(value: TimeValue | null) => {
                        if (value !== null) {
                          const newStartTime = startTime.set({
                            hour: value.hour,
                            minute: value.minute,
                          });
                          const newEndTime = newStartTime.add({ minutes: 20 });

                          setStartTime(newStartTime);
                          setEndTime(newEndTime);
                        }
                      }}
                    />
                  </FormControl>
                  <FormControl isInvalid={endTime < startTime}>
                    <TimePicker
                      id="endTime"
                      label="Sluttid"
                      minuteInterval={15}
                      value={endTime}
                      onChange={(value: TimeValue | null) => {
                        if (value !== null) {
                          const newEndTime = endTime.set({
                            hour: value.hour,
                            minute: value.minute,
                          });
                          setEndTime(newEndTime);
                        }
                      }}
                    />
                    <FormErrorMessage>
                      {selectedLocation.countryCode === "SE"
                        ? "Sluttiden kan inte vara före starttiden"
                        : "Sluttid kan ikke være før starttid"}
                    </FormErrorMessage>
                  </FormControl>
                </Stack>
                <br />
                <Stack flexDirection={"row"}>
                  <Checkbox
                    isChecked={rundvask}
                    onChange={() => {
                      setRundvask(!rundvask);
                    }}
                  >
                    Rundvask
                  </Checkbox>
                </Stack>
                <br />
                {activityTypeSelectionIsEnabledForLocation(
                  selectedLocation
                ) && (
                  <Stack flexDirection={"row"}>
                    <InfoSelect
                      width={"200px"}
                      label={
                        selectedLocation.countryCode === "SE"
                          ? "Välj typ aktivitet"
                          : "Velg type aktivitet"
                      }
                      items={Object.values(ActivityTypes)}
                      onChange={(val) => {
                        const act = getActivityTypeById(val.toString());
                        setSelectedActivityType(
                          act ? act : ActivityTypes.VASK_TANK
                        );
                      }}
                      /* eslint-disable-next-line react/no-children-prop */
                      children={(item) => (
                        <Item key={item.id}>
                          {selectedLocation.countryCode === "SE"
                            ? item.displayName_SE
                            : item.displayName_NO}
                        </Item>
                      )}
                      value={selectedActivityType.id}
                      placeholder={
                        selectedLocation.countryCode === "SE"
                          ? selectedActivityType.displayName_SE
                          : selectedActivityType.displayName_NO
                      }
                    />
                    <Tooltip
                      content={ActivityTypeTooltipContent(
                        selectedActivityType,
                        selectedLocation.countryCode
                      )}
                      placement={"right"}
                    >
                      <IconButton
                        style={InfoButtonStyle}
                        aria-label={"Info"}
                        variant={"ghost"}
                        icon={<InfoIcon />}
                      />
                    </Tooltip>
                  </Stack>
                )}
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="tertiary"
                mr={3}
                type="button"
                onClick={handleModalClose}
              >
                {selectedLocation.countryCode === "SE" ? "Stäng" : "Lukk"}
              </Button>
              <Button variant="primary" type="submit" mr={3}>
                {selectedLocation.countryCode === "SE"
                  ? "Lägg till"
                  : "Legg til"}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};
